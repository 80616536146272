$hamburger-padding-x: 0;
$hamburger-padding-y: 0px;
$hamburger-types: (squeeze);
$hamburger-layer-color: #ffffff;
$hamburger-layer-border-radius: 0px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: #ffffff;
$hamburger-layer-width: 30px;
$hamburger-layer-spacing: 9px;
$hamburger-layer-height: 2px;

.section-header {
	.hamburger {
		@apply mt-1;
	}
}