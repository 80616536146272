#mobile-menu {
	@apply opacity-0 hidden;
}

body.mm-ocd-opened {
	@apply w-full;
	position: fixed !important;
}

.mm ul.mm--parent {
	left: -100%;
	overflow-y: hidden;
}

.mm-ocd {
	top: 130px; 

	#mobile-menu {
		@apply opacity-100 block;
	}

	&__content {
		@apply bg-white w-full;
		max-width: none;
	}

	&__backdrop {
		@apply hidden;
	}

	.mm-spn.mm-spn--navbar ul {
		top: 71px;
	}

	.mm-spn.mm-spn--navbar ul::before {
		@apply border-none;
	}

	.mm-spn.mm-spn--light {
		@apply bg-white;
	}

	.mm-spn li.parent-li:before {
			@apply opacity-100;
		}

		.mm-spn li {
			> a {
				width: 100%;
			}
			&.parent-li {
				> a {
					width: calc(100% - 48px);
				}
				ul {
					opacity: 0;
	
					&.mm-spn--open {
						opacity: 1;
					}
				}
			}
		}

	.mm-spn a {
		@apply text-black bg-white py-6 px-0 text-base font-ibm font-medium uppercase tracking-wider border-b border-solid border-ltGrey;

		&.active {
			//@apply font-bold;
		}

		span {
			@apply p-0;

			&:first-child {
				@apply text-sm font-medium;
			}

			&:last-child {
				@apply text-base normal-case tracking-normal font-normal;
			}
		}
	}

	.mm-spn li::after {
		@apply ml-0 opacity-100 border-t-0;
	}

	.mm-spn a:not(:last-child)::after {
		@apply border-none;
	}

	.mm-spn li {
		@apply px-6;

		&:before { 
			@apply hidden;
		}

		&.active {
			> a {
				@apply pl-5 relative;

				&:before {
					content: "";
					width: 10px;
					height: calc(100% - 48px);
					@apply absolute left-0 bottom-0 mb-6 bg-yellow;
				}
			}
		}
		&.parent-li {
			&:before {
				background: url(../../img/svg/arrow-right-regular.svg) no-repeat;
				background-size: 18px 20px;
				background-position: center;
				width: 48px;
				height: 100%;
				@apply opacity-100 block border-b border-solid border-ltGrey border-t-0 border-r-0;
				transform: rotate(0deg);
				top: 0;
				right: 24px;
			}
		}
	}

	.mm-spn.mm-spn--navbar::before {
		background: url(../../img/svg/chevron-left-light.svg) no-repeat;
		background-size: 12px 18px;
		background-position: center;
		width: 20px;
		height: 25px;
		transform: rotate(0deg);
		top: 20px;
		@apply z-20 opacity-100 border-none;
	}

	.mm-spn.mm-spn--navbar::after {
		@apply opacity-100 text-white text-left pl-12 font-quadon font-normal bg-black pt-2;
		height: 70px;
		top: 1px;
		font-size: 22px;
	}

	.mm-spn.mm-spn--navbar.mm-spn--main::after {
		@apply pl-6;
		content: "Menu" !important;
	}

	.mm-spn {

		.mobile-contact {
			z-index: 9999;
			max-width: 400px;
			left: 50%;
            transform: translateX(-50%);
			@apply w-full absolute bottom-0 px-8 mb-20;

			a {
				@apply flex border-none w-auto font-normal py-3 text-sm;

				span {
					@apply p-0 mx-4;
				}
			}

			@media screen and (max-height: 670px) {
				@apply hidden;
			}
		}
	}
}

.mm-ocd-opened {
	.layout {
		opacity: 0.4;
	}

	.open-nav {
		@apply opacity-0;
	}
}

.mm-ocd--open {
	.mm-ocd__content {
		&:before {
			@apply opacity-100;
		}
	}
}
